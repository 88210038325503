<script setup>
import { getActiveLanguage } from "laravel-vue-i18n";
import Vue3Autocounter from "vue3-autocounter";
import { onBeforeUnmount, onMounted, ref } from "vue";
import { isInViewport } from "@/Modules/Dom";
import "animate.css";
import { toArabicNumbers } from "en-arabicjs";

const Employees_counter = ref(null);
const Projects_counter = ref(null);
const Customers_counter = ref(null);
const Awards_counter = ref(null);
let count_once = true;

const countersStart = () => {
    if (
        isInViewport(
            document.querySelector(".stats-counter .stats-item span")
        ) &&
        count_once
    ) {
        Employees_counter.value.start();
        Projects_counter.value.start();
        Customers_counter.value.start();
        Awards_counter.value.start();
        count_once = false;
    }
};

const Scroll = () => {
    countersStart();
};


function calculateYearsSince(year) {
    const currentYear = new Date().getFullYear();
    const yearsSince = currentYear - year;
    return yearsSince;
}

onMounted(() => {
    window.addEventListener("scroll", Scroll);
    countersStart();
    calculateYearsSince();
});

onBeforeUnmount(() => {
    window.removeEventListener("scroll", Scroll);
});
</script>
<template>
    <section class="py-5" id="about">
        <div class="container">
            <div class="about row justify-content-between pt-5 pb-5">
                <div class="image col-12 col-md-5 pt-3" :class="{ imageArabic: getActiveLanguage() == 'ar' }">
                    <div class="smallImage" :class="{
                        smallImageArabic: getActiveLanguage() == 'ar',
                    }">
                        <img src="/images/Pages/Home/Aboutus/woodBuilding2.jpg" alt="" />
                    </div>
                </div>
                <div class="nextImg col-12 col-md-6 pt-4 p-lg-0">
                    <div class="mobile d-flex align-items-center flex-wrap">
                        <div class="imageNumber">
                            <div class="since text-uppercase" :class="{
                                sinceArabic: getActiveLanguage() == 'ar',
                            }">
                                <p>{{ $t("home.aboutSection.since") }}</p>
                            </div>
                            <h1 class="ps-1">
                                {{ $t("home.aboutSection.age") }}
                            </h1>
                        </div>

                        <div class="">
                            <div class="row justify-content-center align-items-center">
                                <p class="yearsOfSuccess" :class="{
                                    yearsOfSuccessArabic:
                                        getActiveLanguage() == 'ar',
                                }">
                                    <span class="years col-12 text-uppercase">
                                        {{ getActiveLanguage() == 'ar' ?
                                            toArabicNumbers(calculateYearsSince(1997)) : calculateYearsSince(1997) }}
                                        {{
                                            $t("home.aboutSection.years")
                                        }}</span><br />
                                    <span class="success col-12 text-uppercase">
                                        {{
                                            $t("home.aboutSection.success")
                                        }} </span><br />
                                    <span class="work col-12 text-uppercase">
                                        {{ $t("home.aboutSection.work") }}</span><span class="dot">.</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="bottom">
                        <div class="row justify-content-center align-items-center">
                            <div class="caption col-12">
                                <p class="">
                                    {{ $t("home.aboutSection.desc") }}
                                </p>
                            </div>
                            <div class="statistics stats-counter col-12 3">
                                <div class="row justify-content-center align-items-center">
                                    <div class="col-6 col-lg-3">
                                        <div class="number stats-item">
                                            <span><vue3-autocounter ref="Projects_counter" :startAmount="0" :endAmount="150"
                                                    :duration="3" :autoinit="false" /></span>
                                        </div>
                                        <div class="title">
                                            <p class="text-uppercase">
                                                {{
                                                    $t(
                                                        "home.aboutSection.numbers.projects"
                                                    )
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-6 col-lg-3">
                                        <div class="number stats-item">
                                            <span><vue3-autocounter ref="Employees_counter" :startAmount="0" :endAmount="70"
                                                    :duration="3" :autoinit="false" /></span>
                                        </div>
                                        <div class="title">
                                            <p class="text-uppercase">
                                                {{
                                                    $t(
                                                        "home.aboutSection.numbers.employees"
                                                    )
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-6 col-lg-3">
                                        <div class="number stats-item">
                                            <span>
                                                <vue3-autocounter ref="Customers_counter" :startAmount="0" :endAmount="120"
                                                    :duration="3" :autoinit="false" />
                                            </span>
                                        </div>
                                        <div class="title">
                                            <p class="text-uppercase">
                                                {{
                                                    $t(
                                                        "home.aboutSection.numbers.customers"
                                                    )
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-6 col-lg-3">
                                        <div class="number stats-item">
                                            <span><vue3-autocounter ref="Awards_counter" :startAmount="0" :endAmount="4"
                                                    :duration="3" :autoinit="false" /></span>
                                        </div>
                                        <div class="title">
                                            <p class="text-uppercase">
                                                {{
                                                    $t(
                                                        "home.aboutSection.numbers.awards"
                                                    )
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style lang="scss" scoped>
img {
    width: 100%;
}

.about {
    .image {
        position: relative;
        width: 500px;
        height: 600px;
        background-image: url(/images/Pages/Home/Aboutus/aboutImage.jpg);
        background-size: contain;
        background-repeat: no-repeat;

        .smallImage {
            position: absolute;
            width: 50%;
            bottom: 60px;
            right: -95px;

            img {
                border: white 13px solid;
            }
        }

        .smallImageArabic {
            position: absolute;
            width: 50%;
            bottom: 60px;
            right: 345px;
        }
    }

    .image::before {
        content: "";
        position: absolute;
        height: 290px;
        color: black;
        background-color: #e4e4e4;
        width: 10px;
        top: -26px;
        right: -43px;
    }

    .imageArabic::before {
        top: -26px;
        right: 535px;
    }

    .nextImg {
        .imageNumber {
            width: 100%;
            position: relative;

            .since p {
                margin: 0;
                padding: 0;
                position: absolute;
                top: -155px;
                left: -22px;
            }

            .sinceArabic p {
                top: -175px;
                right: 22px;
            }

            h1 {
                background: url(/images/Pages/Home/Aboutus/sky.jpg);
                background-position: center;
                background-position-x: center;
                background-repeat: no-repeat;
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: #69778554;
                animation: move 30s linear infinite;
                letter-spacing: 0.3rem;
            }
        }

        @keyframes move {
            from {
                background-position: 0 80%;
            }

            to {
                background-position: 100% 50%;
            }
        }

        .since {
            position: relative;
            top: 130px;
            left: 20px;
            color: black;

            P {
                margin: 0;
                color: rgb(130, 130, 130);
            }
        }

        .yearsOfSuccess {
            line-height: 1;

            .years {
                color: grey;
            }

            .success {
                color: $primary;
            }

            .dot {
                color: $primary;
            }
        }

        .yearsOfSuccessArabic {
            line-height: 1.3;
        }

        .caption {
            p {
                color: $gray;
            }
        }

        .statistics {
            .number {
                color: black;
                font-size: 25px;
                font-weight: 700;
                text-align: center;
            }

            .title {
                color: $gray;
                text-align: center;
                font-size: 18px;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .about {
        margin: 0;

        .imageArabic {
            height: 330px !important;
            border-radius: 8px;
            overflow: hidden;
        }

        .image {
            justify-content: none;
            align-items: none;
            margin: auto;
            height: 330px !important;
            width: 80%;
            background-size: cover !important;
            border-radius: 8px;
            overflow: hidden;

            .smallImage {
                display: none;
            }

            &::before {
                display: none !important;
            }
        }

        .nextImg {
            padding-top: 3.5rem !important;

            .mobile {
                justify-content: center !important;
            }

            .since {


                P {
                    margin: 0;
                    font-weight: 900;
                    font-size: 28px;
                    color: rgb(130, 130, 130);
                }
            }

            .caption {
                p {
                    font-size: 13px;
                }
            }

            .imageNumber {
                .since p {
                    top: -150px !important;
                    left: 100px !important;
                }

                .sinceArabic p {
                    top: -180px !important;
                    right: 122px !important;
                }

                h1 {
                    text-align: center;
                    padding: 0;
                    font-size: 100px !important;
                    font-weight: 700 !important;
                }
            }

            .yearsOfSuccess {
                line-height: 1;

                .years {
                    color: grey;
                    font-size: 35px !important;
                }

                .success {
                    color: $primary;
                    font-weight: 700 !important;
                    font-size: 43px !important;
                }

                .work {
                    font-size: 53px;
                }

                .dot {
                    font-size: 60px !important;
                    color: $primary;
                }
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .image {
        // margin: auto;
        position: relative;
        width: 275px !important;
        height: 430px !important;

        .smallImage {
            right: -80px !important;
            bottom: 125px !important;
        }

        .smallImageArabic {
            right: 210px !important;
            bottom: 125px !important;
        }
    }

    .image::before {
        right: -40px !important;
    }

    .imageArabic::before {
        right: 300px !important;
    }

    .nextImg {
        .mobile {
            justify-content: start !important;
        }

        .caption {
            font-size: 12px !important;
        }

        .imageNumber {
            .since p {
                top: -155px !important;
                left: -17px !important;
                font-size: 25px !important;
                font-weight: 600 !important;
            }

            h1 {
                // text-align: center;
                padding: 0;
                font-size: 60px !important;
                font-weight: 700 !important;
                letter-spacing: 0.3rem;
            }
        }

        .row {
            padding: 0 !important;

            .statistics {
                padding: 15px !important;
            }
        }

        .yearsOfSuccess {
            .years {
                font-size: 16px !important;
            }

            .success {
                font-size: 30px !important;
                font-weight: 700 !important;
            }

            .work {
                font-size: 40px !important;
            }
        }

        .statistics {
            .number {
                color: black;
                font-size: 16px !important;
                font-weight: 600 !important;
                text-align: center;
            }

            .title {
                color: $gray;
                text-align: center;
                font-size: 17px !important;
            }
        }
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .image {
        // background-color: rgb(255, 13, 0);
        position: relative;
        width: 340px !important;
        height: 510px !important;

        .smallImage {
            right: -80px !important;
            bottom: 125px !important;
        }

        .smallImageArabic {
            right: 270px !important;
            bottom: 125px !important;
        }
    }

    .image::before {
        right: -40px !important;
    }

    .imageArabic::before {
        right: 385px !important;
    }

    .nextImg {
        .imageNumber {
            .since p {
                font-size: 35px !important;
                font-weight: 600;
            }

            h1 {
                font-size: 80px !important;
                font-weight: 900;
                letter-spacing: 0.3rem;
            }
        }

        .row {
            padding: 0 !important;

            .statistics {
                padding: 15px !important;
            }
        }

        .yearsOfSuccess {
            .years {
                font-size: 20px !important;
            }

            .success {
                font-size: 35px !important;
                font-weight: 700 !important;
            }

            .work {
                font-size: 45px !important;
            }
        }
    }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
    .image {
        position: relative;
        width: 400px !important;
        height: 510px !important;

        .smallImage {
            right: -80px !important;
        }

        .smallImageArabic {
            right: 315px !important;
        }
    }

    .image::before {
        right: -30px !important;
    }

    .imageArabic::before {
        right: 420px !important;
    }

    .nextImg {
        .imageNumber {
            .since p {
                font-size: 35px !important;
                font-weight: 600;
            }

            h1 {
                font-size: 100px !important;
                font-weight: 900;
                letter-spacing: 0.3rem;
            }
        }

        .yearsOfSuccess {
            line-height: 1;

            .years {
                color: grey;
                font-size: 35px;
            }

            .success {
                color: $primary;
                font-weight: 700;
                font-size: 60px;
            }

            .dot {
                font-size: 60px;
                color: $primary;
            }

            .work {
                font-size: 60px;
            }
        }
    }
}

@media screen and (min-width: 1400px) {
    .imageNumber {
        .since p {
            font-size: 35px !important;
            font-weight: 600;
        }

        h1 {
            font-size: 100px !important;
            font-weight: 900;
            letter-spacing: 0.3rem;
        }
    }

    .yearsOfSuccess {
        line-height: 1;

        .years {
            color: grey;
            font-size: 35px;
        }

        .success {
            color: $primary;
            font-weight: 700;
            font-size: 60px;
        }

        .dot {
            font-size: 60px;
            color: $primary;
        }

        .work {
            font-size: 60px;
        }
    }
}
</style>
